import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css'; // We'll update this file for styling
import logo from '../assets/logo.png';

const Header = () => {
  const navigate = useNavigate();

  const handleNavigation = (section,route) => {
    document.documentElement.classList.add('smooth-scroll');

    if (window.location.pathname !== '/') {
      navigate(route, { state: { scrollTo: section } });
    } else {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

    setTimeout(() => {
      document.documentElement.classList.remove('smooth-scroll');
    }, 100);
  }
  return (
    <nav className="header">
        <div className="logo">
          <a href='/'><img src={logo} alt="K.H.P.T Logo" /></a>
          <a href='/'><h1>K.H.P.T</h1></a>
        </div>
        <nav className='navframe'>
          <ul>
            <li><a className="smooth-scroll" href="#services" onClick={() => handleNavigation('services','/')}>Services</a></li>
            <li><a href="#products" onClick={() => handleNavigation('products','/')}>Products</a></li>
            <li><a href="#perspective" onClick={() => handleNavigation('approach','/')}>Our Perspective</a></li>
          </ul>
        </nav>
        <div className="contact-button">
            <a href="/contactus" className="contact-button-text">Contact us</a>
        </div>
    </nav>
  );
}

export default Header;
