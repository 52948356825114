import React, { useState, useEffect, forwardRef } from 'react';
import '../styles/ServicesSection.css';
import serviceIcon1 from '../assets/service-icon-1.png';
import serviceIcon2 from '../assets/service-icon-2.png';
import serviceIcon3 from '../assets/service-icon-3.png';
import serviceIcon4 from '../assets/service-icon-2.png';
import serviceIcon5 from '../assets/service-icon-3.png';

const ServicesSection = forwardRef((props, ref) => {
  const [activeIndex, setActiveIndex] = useState(2); // Default to the center card (index 2)

  const services = [
    { icon: serviceIcon5, title: 'Digital Marketing', description: 'Boost your online presence with our comprehensive digital marketing strategies.' },
    { icon: serviceIcon2, title: 'Web Design & Development', description: 'A Website is an extension of yourself and we can help you to express it properly. Your website is your number one marketing asset because we live in a digital age.' },
    { icon: serviceIcon1, title: 'Mobile App Development', description: 'A Website is an extension of yourself and we can help you to express it properly. Your website is your number one marketing asset because we live in a digital age.' },
    { icon: serviceIcon4, title: 'Cloud Solutions', description: 'Cloud Solutions tailored to your needs, ensuring scalable and secure infrastructure for your applications.' },
    { icon: serviceIcon3, title: 'Software Testing Service', description: 'A Website is an extension of yourself and we can help you to express it properly. Your website is your number one marketing asset because we live in a digital age.' },
    
  ];

  const scrollToCard = (index, smooth = true) => {
    const card = document.getElementById(`service-card-${index}`);
    if (card) {
      const scrollBehavior = smooth ? 'smooth' : 'auto';
      const originalScroll = window.scrollY; // Save the current scroll position
  
      card.scrollIntoView({ behavior: scrollBehavior, inline: 'center' });
  
      window.scrollTo(0, originalScroll); // Restore the scroll position to top
    }
    setActiveIndex(index);
  };

  // Use useEffect to scroll to the center card on initial load
  useEffect(() => {
    scrollToCard(activeIndex,false);
  }, []);

  return (
    <section ref={ref} className="services-section" id="services">
      <div class="title-bar"></div>
      <h2 className='bold-text'>Services we offer</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div
            key={index}
            id={`service-card-${index}`}
            className={`service-card ${index === activeIndex ? 'highlighted' : ''}`}
            onClick={() => scrollToCard(index)}
          >
            <img src={service.icon} alt={service.title} />
            <h3 className='semi-bold-text'>{service.title}</h3>
            <p className='regular-text'>{service.description}</p>
          </div>
        ))}
      </div>
      <div className="dots-navigation">
        {services.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === activeIndex ? 'active' : ''}`}
            onClick={() => scrollToCard(index)}
          ></span>
        ))}
      </div>
      <div className="progress-navigation">
        <span>{String(activeIndex + 1).padStart(2, '0')}</span>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${((activeIndex + 1) / services.length) * 100}%` }}></div>
        </div>
        <span>{String(services.length).padStart(2, '0')}</span>
      </div>
    </section>
  );
});

export default ServicesSection;
