import React from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        KHPT Company Limited and our associates/partners/successors/permitted assigns ("KHPT Company Limited", "we", "us" and "our") are fully committed to respecting your privacy and are committed to protecting it through our compliance with this privacy policy ("Policy"). Reference to "you" in this Policy refers to the users of the KHPT Company Limited Platform (as defined below), whether or not you access the services available on the Platform or consummate a transaction on the Platform ("Users").
      </p>
      <p>
        The Policy sets out: (a) the types of information that we may collect from you when you access or use our services (collectively, our "Services") through our Platform, and (b) our practices for collecting, using, maintaining, protecting and disclosing that information. The Website and Around Us App, either directly or via licenses assigned by us, are jointly referred to as the "Platform".
      </p>
      <p>
        By using or accessing this Platform and providing Information (as defined below) or SPDI (as defined below), you agree to the terms and conditions of this Policy. You also expressly consent to our use and disclosure of your Information and/or SPDI in any manner as described in this Policy and further signify your agreement to this Policy and the Terms of Use (being incorporated by reference herein). If you do not agree with the terms and conditions of this Policy, please do not proceed further or use or access this Platform/provide us with any information.
      </p>

      <h2>I. Definitions</h2>
      <p><strong>"Account"</strong> refers to the password-protected account created by users to access and participate in the Platform.</p>
      <p><strong>"Non-personal Identification Information"</strong> refers to any non-personal information collected from Users pursuant to their interaction on the Platform, including but not limited to the browser name, the type of computer and technical information about Users means of connection to the Platform such as the operating system, the Internet service providers utilized and other similar information.</p>
      <p><strong>"Personal Identification Information"</strong> refers to any information that identifies or can be used to identify, contact or locate the person, to whom such information pertains including, but not limited to, when Users visit the Platform, register, conclude a transaction, respond to a survey, fill out a form, and anything else in connection with other activities, services, features or resources we make available on the Platform. Users may be asked for personal information including, but not limited to name, email address, phone number and residential address disclosed by you in relation to the services available on the Platform. Users may, however, visit our site anonymously. KHPT Company Limited will collect Personal Identification Information from Users only if they voluntarily submit such information to us. Users can always refuse to supply Personally Identification Information, except that it may prevent them from engaging in certain activities/services available on the Platform. For the purposes of this Policy, Personal Identification Information and Non-personal Identification Information shall together be referred to as "Information".</p>
      <p><strong>"Sensitive personal data or information" / "SPDI"</strong> consists of information relating to the following: passwords; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above clauses as provided to body corporate for providing service; and any of the information received under above clauses by body corporate for processing, stored or processed under lawful contract or otherwise.</p>
      <p><strong>"Third Party"</strong> refers to any person or entity other than you or us.</p>

      <h2>II. Collection of Personal Information by KHPT Company Limited</h2>
      <p>
        For the purposes of creation of an Account on the Platform, Users will be required to disclose information including personal contact details. The type of Information collected from a User varies based on the interaction with the Platform. During the creation of an Account for Users, we will collect information such as your name, telephone number, email address, residential/commercial address and other Information. In some situations, we will also require you to disclose demographic information including gender, age, education and other information relevant to the provision of Services. We may also collect Information that you post in your offer, profile, requirements, or feedback and any other correspondence site on the Platform.
      </p>
      <p>
        When you use our location-enabled services, we may collect and process information about your mobile device's GPS location (including the latitude, longitude or altitude) and the time the information is recorded, to customize the Services. We may associate your location data with your device ID and other information we hold about you. You can withdraw your consent for this feature by disabling the GPS or any other location-tracking functions on your device.
      </p>
      <p>
        We also reserve the right to monitor conversations between two Users facilitated by KHPT Company Limited through any mechanism on the Platform. This information can be used to but not limited to monitor and prohibit abuse, safeguard the rights of the Users and resolve any disputes that may arise.
      </p>
      <p>
        From third-party sites. You may also register for an Account using your existing Facebook, Google, Apple, or such other accounts and log-in credentials (your "Third-Party Site Account"). As part of the functionality of the Platform, you may link your account with Third Party Site Accounts, by either: (i) providing your Third Party Site Account login information to us through the Platform; or (ii) allowing us to access your Third Party Site Account, as is permitted under the applicable terms and conditions that govern your use of each Third Party Site Account.
      </p>
      <p>
        You represent that you are entitled to disclose your Third Party Account login information to us and/or grant us access to your Third Party Account (including, but not limited to, for the purposes described herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third Party Account and without obligating us to pay any fees or making us subject to any usage limitations imposed by the applicable Third Party Site service provider.
      </p>

      <h2>III. Use of Personal Information by KHPT Company Limited</h2>
      <p>
        For the purposes of providing Services on the Platform. In the event we are required to respond to subpoenas, court orders or other legal process, your Information may be disclosed pursuant to such subpoena, court order or legal process, which may be without notice to you. We will also disclose your Information, including, without limitation, your name, city, state, telephone number, email address and activity history on the Platform, to law enforcement agencies or other government officials if we are required to do so by law, regulation or any other governmental authority or otherwise in cooperation with an investigation of a governmental authority.
      </p>
      <p>
        Communication. We may offer phone call, email, application notifications, short message service, multimedia message service or other forms of communication to share information with you about certain promotions or features the Platform may choose to offer or about our affiliates, subsidiaries, business partners, advertisers and sponsors, including company news, updates, related product or service information.
      </p>
      <p>
        We do not, however, sell to any Third Party user-specific Information for the above purposes, and all data shared with Third Parties in relation to the above purposes, will be on a no-name aggregate basis.
      </p>

      <h2>IV. Sharing of Personal Information by KHPT Company Limited</h2>
      <p>
        We do not sell, trade, or rent Users' Information to others. We may share generic aggregated demographic information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
      </p>
      <p>
        However, Personal Identification Information may be shared if required, with (a) our subsidiaries or affiliates (b) a buyer or successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of KHPT Company Limited's assets, whether as a going concern or as a part of bankruptcy, liquidation or similar proceeding, in which Information of Users held by KHPT Company Limited are among the assets transferred.
      </p>
      <p>
        Legal proceedings and law enforcement. In the event we are required to respond to subpoenas, court orders or other legal process, your Information may be disclosed pursuant to such subpoena, court order or legal process, which may be without notice to you.
      </p>

      <h2>V. Security</h2>
      <p>
        We strive to ensure the security, integrity and privacy of your Information and SPDI and to protect it against unauthorized access or unauthorized alteration, disclosure or destruction. We implement appropriate physical, electronic and managerial procedures to safeguard and help prevent unauthorized access and for the purposes of maintaining data security. However, we cannot guarantee absolute security as no method of protection and transmission of data is completely secure. 
      </p>

      <h2>VI. Updating, Deleting and Amending Your Information</h2>
      <p>
        We will take reasonable steps to accurately record the Information that you provide to us including any subsequent updates. You can review, update and amend the Information that we maintain about you, and you may request we delete Information about you that is inaccurate, incomplete or irrelevant for legitimate purposes, or is being processed in a way which infringes any applicable legal requirement.
      </p>

      <h2>VII. Link to Third Party Apps and Websites</h2>
      <p>
        The Platform and any communication sent to you may also contain/display Third Party advertisements and links to other websites or products and services. You agree and acknowledge that these applications and websites are operated by Third Parties and are not controlled by, or affiliated to, or associated with us unless expressly specified. 
      </p>

      <h2>VIII. Amendment to the Policy</h2>
      <p>
        KHPT Company Limited has the discretion to update this Policy at any time. When we do, we will revise the updated date at the bottom of this Policy and additionally also send you an e-mail apprising you of the same. 
      </p>

      <h2>IX. Third Party Policies</h2>
      <p>
        As you access and use our services, we collect certain information from you, including but not limited to, phone number, email address, device make-details, and IP address. By accessing and using our services, you expressly consent to the sharing and disclosure of your information so collected, with our third-party service providers, business partners, and agents.
      </p>

      <h2>X. Contact Us</h2>
      <p>
        If you have any queries relating to the processing/usage of Information or SPDI provided by you or this Policy, you may email us at support@khpt.com.vn or write to the following address:
      </p>
      <p>
        K.H.P.T Company Limited,<br />
        405/19 Nguyễn Oanh, <br />
        Phường 17,  Quận Gò Vấp,<br />
        Ho Chi Minh City,  Vietnam - 71413
      </p>
      <p>
        This document was last updated on 12 October 2023.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
