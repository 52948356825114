import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/Home';
import ContactUs from './components/ContactUs';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  const servicesRef = useRef(null);
  const productsRef = useRef(null);
  const approachRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Router>
      <Header scrollToSection={scrollToSection} servicesRef={servicesRef} productsRef={productsRef} approachRef={approachRef}/>
      <Routes>
        <Route path="/" exact element={<Home servicesRef={servicesRef} productsRef={productsRef} approachRef={approachRef}/>} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <ScrollToTopButton />
      <Footer scrollToSection={scrollToSection} servicesRef={servicesRef} productsRef={productsRef} approachRef={approachRef}/>
    </Router>
  );
}

export default App;