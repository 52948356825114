import React, { forwardRef } from 'react';
import '../styles/ProductLaunches.css';
import quickFeedImage from '../assets/quickfeed.png'; // Replace with actual image path
import aroundUsImage from '../assets/aroundus.png'; // Replace with actual image path

const ProductLaunches = forwardRef((props, ref) => {
  const products = [
    {
      name: 'QuickFeed',
      description: 'QuickFeed is a powerful news aggregator and summarization app that delivers concise, relevant news updates in real-time. Stay informed with bite-sized summaries tailored to your preferences.',
      image: quickFeedImage,
      link: 'https://quickfeed-app.wstd.io/home',
      cardClassName: 'product-card-quickfeed',
      detailClassName: 'product-detail-quickfeed',
    },
    {
      name: 'AroundUs',
      description: 'AroundUs is the ultimate sports booking app in Vietnam, connecting users with sports venues and activities. Book your favorite sports and enjoy seamless experiences across various locations.',
      image: aroundUsImage,
      link: 'https://aroundusapp.com/',
      cardClassName: 'product-card-aroundus',
      detailClassName: 'product-detail-aroundus',
    },
  ];

  return (
    <section ref={ref} className="product-launches-section" id="products">
      <div class="title-bar"></div>
      <h2 className='regular-text'>Our recent <br/><span className='bold-text'>Product Launches</span></h2>
      {products.map((product, index) => (
        <div className={product.cardClassName} key={index}>
          <div className="product-image">
            <img src={product.image} alt={product.name} />
          </div>
          <div className={`product-details ${product.className}`} >
            <h3 className='semi-bold-text'>{product.name}</h3>
            <p className='regular-text'>{product.description}</p>
            <a href={product.link} className="read-more semi-bold-text">Read more &gt;</a>
          </div>
        </div>
      ))}
    </section>
  );
});

export default ProductLaunches;
