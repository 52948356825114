import React from 'react';
import '../styles/HeroSection.css';
import illustration from '../assets/illustration.png'; // Make sure to replace this with the actual image path

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1 className='light-text'>
          Great <span className="highlight bold-text">Product</span> is <br /> <span className='extra-bold-text fontcss'>built by great</span><span className="highlight1 extra-bold-text fontcss"> teams</span>
        </h1>
        <p>
          We help build and manage a team of world-class developers <br />
          to bring your vision to life
        </p>
        {/* <a href="#get-started" className="cta-button">Let's get started!</a> */}
      </div>
      <div className="hero-image">
        <img src={illustration} alt="Team working together" />
      </div>
      {/* Add this for the semi-circle */}
      {/* <div className="semi-circle"></div>  */}
    </section>
  );
}

export default HeroSection;
