import React, { useState } from 'react';
import '../styles/ContactUs.css';

function ContactUs() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    subject: 'General Inquiry',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://aroundusapp.com.vn/en/au_admin/khpt_contact_us/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('We have received your information, we will get in touch with you soon!');
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          mobile_no: '',
          subject: 'General Inquiry',
          message: ''
        });
      } else {
        alert('Something went wrong, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error with your submission, please try again.');
    }
  };

  return (
    <div className="contact-us-container">
      <div className="contact-form">
        <h2 className='extra-bold-text'>Contact Us</h2>
        <p className='regular-text'>Any question or remarks? Just write us a message!</p>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input 
              type="text" 
              name="first_name" 
              value={formData.first_name} 
              onChange={handleChange} 
              placeholder="First Name" 
              required />
            <input 
              type="text" 
              name="last_name" 
              value={formData.last_name} 
              onChange={handleChange} 
              placeholder="Last Name" 
              />
          </div>
          <div className="form-row">
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              placeholder="Email" 
              required />
            <input 
              type="text" 
              name="mobile_no" 
              value={formData.mobile_no} 
              onChange={handleChange} 
              placeholder="Phone Number" 
              />
          </div>
          <div className="form-row">
            <label className='regular-text'>Subject:</label>
            <select 
              name="subject" 
              value={formData.subject} 
              onChange={handleChange}>
              <option value="General Inquiry">General Inquiry</option>
              <option value="Support">Support</option>
              <option value="Feedback">Feedback</option>
            </select>
          </div>
          <div className="form-row">
            <textarea 
              name="message" 
              value={formData.message} 
              onChange={handleChange} 
              placeholder="Write your message.." 
              required></textarea>
          </div>
          <button type="submit" className="cta-button-contactus regular-text">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
