import React from 'react';
import '../styles/CTABanner.css';

const CTABanner = () => {
  return (
    <section className="cta-banner">
      <div className="cta-content">
        <h2>Hire the best developers and <br/> designers around!</h2>
        <a href="/contactus" className="cta-button">Hire Top Developers</a>
      </div>
    </section>
  );
};

export default CTABanner;
