import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import HeroSection from './HeroSection';
import ServicesSection from './ServicesSection';
import ProductLaunches from './ProductLaunches';
import DesignApproach from './DesignApproach';
import DevelopmentFlow from './DevelopmentFlow';
import CTABanner from './CTABanner';
import Footer from './Footer';

import '../styles/Header.css';
import '../styles/HeroSection.css';
import '../styles/ServicesSection.css';
import '../styles/ProductLaunches.css';
import '../styles/DesignApproach.css';
import '../styles/DevelopmentFlow.css';
import '../styles/CTABanner.css';
import '../styles/Footer.css';


function Home ({servicesRef, productsRef, approachRef }) {
    const location = useLocation();

    useEffect(() => {
        if (location.state?.scrollTo) {
            const sectionId = location.state.scrollTo;
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                // sectionElement.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                  }, 100); 
            }
        }
      }, [location]);

    return (
        <div className="Home">
        <HeroSection /> 
        <ServicesSection id="services" ref={servicesRef}/>
        <ProductLaunches id="products" ref={productsRef}/>
        <DesignApproach id="approach" ref={approachRef}/>
        {/* <DevelopmentFlow /> */}
        <CTABanner />
        {/* Add more components here */}
        </div>
    );
};

export default Home;