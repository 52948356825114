import React,{forwardRef} from 'react';
import '../styles/DesignApproach.css';
import uxDrivenIcon from '../assets/ux-driven-icon.png'; // Replace with actual path
import sharedUnderstandingIcon from '../assets/shared-understanding-icon.png'; // Replace with actual path
import experienceIcon from '../assets/experience-icon.png'; // Replace with actual path
import securityIcon from '../assets/security-icon.png'; // Replace with actual path
import codeReviewsIcon from '../assets/code-reviews-icon.png'; // Replace with actual path
import qualityIcon from '../assets/quality-icon.png'; // Replace with actual path

const DesignApproach = forwardRef((props, ref) => {
  const approaches = [
    {
      title: 'UX Driven Engineering',
      description: 'Our approach starts with UX at the forefront, ensuring that every design decision is informed by user experience and research.',
      icon: uxDrivenIcon,
      approachIconClass:'approach-icon-ux'
    },
    {
      title: 'Developing Shared Understanding',
      description: 'We prioritize communication and shared understanding among all stakeholders to ensure alignment on project goals.',
      icon: sharedUnderstandingIcon,
      approachIconClass:'approach-icon-shared'
    },
    {
      title: 'Proven Experience and Expertise',
      description: 'With years of experience, our team brings deep expertise in design and development to every project.',
      icon: experienceIcon,
      approachIconClass:'approach-icon-exp'
    },
    {
      title: 'Security & Intellectual Property (IP)',
      description: 'We safeguard your intellectual property with stringent security measures and a focus on data protection.',
      icon: securityIcon,
      approachIconClass:'approach-icon-security'
    },
    {
      title: 'Code Reviews',
      description: 'Regular code reviews are integral to our process, ensuring quality, consistency, and maintainability of the codebase.',
      icon: codeReviewsIcon,
      approachIconClass:'approach-icon-review'
    },
    {
      title: 'Quality Assurance & Testing',
      description: 'Our QA process is thorough and rigorous, designed to catch issues early and ensure a flawless user experience.',
      icon: qualityIcon,
      approachIconClass:'approach-icon-testing'
    },
  ];

  return (
    <section ref={ref} className="design-approach-section" id='approach'>
      <h2 className='regular-text'>Our design and <br/><span className='bold-text'>development approach</span></h2>
      <div className="approach-grid">
        {approaches.map((approach, index) => (
          <div className="approach-card" key={index}>
            <div className={`approach-icon ${approach.approachIconClass}`}>
              <img src={approach.icon} alt={approach.title} />
            </div>
            <div className="approach-details">
              <h3 className='semi-bold-text'>{approach.title}</h3>
              <p className='regular-text'>{approach.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

export default DesignApproach;
